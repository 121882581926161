.pagination-control {    
    background-color: #0A71E9;
    border: 0;
    border-radius: 25px;
    color: white;
    height: 50px;
    width: 50px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.15);
    margin: 0 3px;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
}
.header {
	height: 70px;
	background-color: #0A71E9;
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.15);
	margin-bottom: 15px;
}

.logo {
    background-image: url('./assets/logo.png');
    height: 33px;
	width: 101px;
	margin: 14.5px 29.5px;
}

.share-button {	
	color: rgba(255,255,255,0.8);
	border-color: rgba(255,255,255,0.5);
	border-width: 2px;
	width: 80px;
	height: 41px;
	font-weight: bold;
	margin: 14.5px 29.5px;
}
.share-button:hover {
	color: #0A71E9;
	background-color: white;	
}
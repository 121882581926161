.footer {
    position: sticky;
    left: 30px;
    bottom: 30px;
    width: 100%;
    margin-top: 20px;
}

.signature {
    background-image: url('./assets/signature.png');
    height: 55px;
    width: 63px;
}

.pagination-controls {
    padding-right: 15px;
}
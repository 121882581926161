.canvas-control {
    height: 60px;
    width: 90px;
    min-width: 70px;
    color: rgba(255,255,255,0.33);
    margin-top: 5px;
    margin-right: 5px;
    transition: margin-top .3s ease-in-out;
    
    label {
        width: inherit;
        min-width: inherit;
        margin: 0;
        padding: 7px 0;

        &:hover {
            cursor: pointer;
        }
    }

    &:hover, &.active {
        border-radius: 3px;
        background-color: white;
        color: #0A71E9;
        font-weight: bold;
        overflow: hidden;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.33);

        margin-top: 15px;

        .icon { opacity: 1; }
        .icon-type { background-image: url('./assets/icon-type-on.svg'); }
        .icon-pencil { background-image: url('./assets/icon-pencil-on.svg'); }
        .icon-rectangle { background-image: url('./assets/icon-rectangle-on.svg'); }
        .icon-circle { background-image: url('./assets/icon-circle-on.svg'); }
        .icon-select { background-image: url('./assets/icon-select-on.svg'); }
    }
    
    .title {
        text-align: center;
        margin-top: 8px;
        padding: 0 10px;
        font-size: 15px;
        line-height: 15px;
    }

    .icon {
        opacity: 0.5;
        margin: 0 auto;
    }

    .icon-type {
        background-image: url('./assets/icon-type-off.svg');
        height: 23.21px;
        width: 25px;  
    }

    .icon-pencil {
        background-image: url('./assets/icon-pencil-off.svg');
        height: 24.56px;
        width: 24.51px;
    }
    
    .icon-rectangle {
        background-image: url('./assets/icon-rectangle-off.svg');
        height: 25px;
        width: 25px;
    }
    
    .icon-circle {
        background-image: url('./assets/icon-circle-off.svg');
        height: 25px;
        width: 25px;
    }

    .icon-select {
        background-image: url('./assets/icon-select-off.svg');
        height: 24px;
        width: 25px;
    }
}
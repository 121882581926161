$font-prefix: "~@ibm/plex";
@import "~@ibm/plex/scss/ibm-plex.scss";

body {
    font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
    background-color: #e5e5e5;
}

.canvas-container {
    &:focus { outline: none; }
}

#c {
    background-color: white;
}